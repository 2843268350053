import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../lib/page';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    '& img, & h6': {
      marginBottom: theme.spacing(4),
    },
  },
  cta : {
    height: '48px',
    margin: theme.spacing(2, 0),
  },
  input: {
    background: 'white',
  },
  linkBox: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      marginBottom: theme.spacing(2),
    }
  }
}));


/**
 * Landing page that collects a prospective users name, email, linkedin, and
 * phone number before sending them to thank you
 */
function SimpleIndex() {
  const classes = useStyles();
  return (
    <Page>
      <Container maxWidth="sm" className={classes.container}>
        <img src="https://greenwoodx.s3.us-east-2.amazonaws.com/purple_logo.svg" alt="Greenwoodx logo" style={{width: "45%"}}/>
        <Typography variant="h6" align="center">
          We need to unlock the global combined power of Black professionals to create massive economic and social progress through conversations and networking.
        </Typography>
        <Typography variant="h6" align="center">
			We find the best top Black professionals related to your goals and introduce you directly to them.
        </Typography>
        <Typography variant="subtitle2" align="center">
          You can pre-signup and join our waitlist. We look forward to welcoming you.
        </Typography>
        <Button
          href="https://bit.ly/greenwoodx-early-access"
          color="primary"
          variant="contained"
          fullWidth
          className={classes.cta}
        >
          Get your invite to Greenwoodx
        </Button>


        <Box className={classes.linkBox}>
          <Link
            href="https://bit.ly/what-is-greenwoodx"
            target="_blank"
            rel="noopener"
          >
            <span role="img" aria-label="Light Bulb Icon">💡</span> What is Greenwoodx?
          </Link>
          <Link
            href="https://forms.gle/GFdvC5Q9S36PmneNA"
            target="_blank"
            rel="noopener"
          >
            <span role="img" aria-label="Mail Icon">✉️</span> Contact Us
          </Link>
        </Box>
      </Container>
    </Page>
  );
}

export default SimpleIndex;
