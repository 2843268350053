import React from "react"
import {Helmet} from "react-helmet"
import {
  CssBaseline,
  ThemeProvider,
} from "@material-ui/core"
import Theme from './theme'

const Page = ({children}) => {
  return (
    <ThemeProvider theme={Theme}>
        <CssBaseline/>
        <Helmet>
          <meta
            name="viewport"
            content="user-scalable=no, width=device-width" />
        </Helmet>
        {children}
    </ThemeProvider>
  )
}

export default Page
